<script setup lang="ts">
import { NuxtLink } from "#components";
import { useFocusTrap } from "@vueuse/integrations/useFocusTrap";

const { isSearchOpen } = useSearch();
const { isMobile } = useUserDevice();

const searchInput = ref<any | null>(null);
const target = templateRef<HTMLFormElement>("target");

const closeSearch = () => {
  if (isSearchOpen.value) {
    setTimeout(() => (isSearchOpen.value = false), 50);
  }
};

onClickOutside(target, closeSearch);
onKeyStroke("Escape", closeSearch, { target });

const { activate, deactivate } = useFocusTrap(target, {
  immediate: false,
  allowOutsideClick: true,
  clickOutsideDeactivates: true,
  escapeDeactivates: true,
});

const updateFocus = (isOpen: boolean) => {
  nextTick(() => {
    if (isOpen) {
      activate();
      searchInput.value?.$el?.focus();
    } else {
      deactivate();
    }
  });
};

watch(isSearchOpen, updateFocus, { immediate: true });
onMounted(() => updateFocus(isSearchOpen.value));

const query = ref("");

const { search, result } = useMeiliSearch("posts");

watch(
  query,
  async (q) =>
    await search(q, {
      attributesToHighlight: ["title"],
      sort: ["date_published:desc"],
      highlightPreTag: "<strong>",
      highlightPostTag: "</strong>",
      showRankingScore: true,
      limit: query.value ? 5 : 2,
    }),
  { immediate: true }
);

const results = computed(() => result.value?.hits || []);

const { getPostLink } = usePosts();

const selectedItem = ref<Record<string, any> | null>(null);

const key = ref(0);

watch(selectedItem, async (item) => {
  if (item && isSearchOpen.value) {
    closeSearch();
    selectedItem.value = null;
    query.value = "";
    key.value++;
    nextTick(() => updateFocus(false));
    await navigateTo(getPostLink(item.type, item.slug));
  }
});

const scrollLock = useScrollLock(window, false);

watch(
  isSearchOpen,
  (isOpen) => {
    if (isOpen) {
      if (isMobile.value) {
        setTimeout(() => (scrollLock.value = true), 100);
      }
    } else {
      scrollLock.value = false;
    }
  },
  { immediate: true }
);
</script>

<template>
  <form
    ref="target"
    action="/"
    class="absolute inset-5 p-5 pt-7 lg:p-0 lg:relative lg:inset-0 app-header-search flex items-center gap-4 z-10"
    @submit.prevent=""
  >
    <div class="app-header-search__icon flex items-center">
      <Icon name="ns:search" />
    </div>

    <HeadlessCombobox
      v-model="selectedItem"
      :key="key"
      :nullable="true"
      :default-value="null"
      v-slot="{ open }"
    >
      <HeadlessComboboxInput
        @change="query = $event.target.value"
        :display-value="(item: any) => item?.title"
        :default-value="''"
        ref="searchInput"
        name="q"
        type="text"
        class="app-header-search__input h-7 grow outline-none font-semibold text-base leading-7"
        :placeholder="
          isMobile
            ? 'Wpisz frazę jakiej szukasz'
            : 'Wpisz frazę jakiej szukasz i wybierz lub wciśnij enter'
        "
        autocomplete="off"
      />

      <HeadlessComboboxButton
        type="submit"
        class="app-header-search__button sr-only"
        title="Szukaj"
        tabindex="-1"
      >
      </HeadlessComboboxButton>

      <div
        class="app-header-search__overlay fixed inset-0 -z-30 bg-black/50"
        @click="closeSearch"
      ></div>

      <HeadlessComboboxOptions
        class="app-header-search__results absolute top-0 left-0 right-0 lg:-top-5 lg:-start-5 lg:-end-14 lg:bottom-auto -z-10 bg-white lg:h-auto pt-[4.125rem] lg:pt-[5rem] rounded-s-[2rem] rounded-b-[2rem] lg:rounded-b-[2.5rem] lg:rounded-s-[2.5rem]"
        static
      >
        <ClientOnly>
          <div
            class="app-header-search__results-container pt-[0.375rem] lg:pt-1 px-6 lg:px-10 !pl-[3.75rem] pb-5 lg:pb-10 container"
          >
            <div
              class="app-header-search__results-wrapper pt-0 flex flex-col items-stretch gap-4"
            >
              <div class="app-header-search__results-content">
                <ul
                  v-if="results.length > 0"
                  class="app-header-search__results-list flex flex-col items-stretch gap-4 text-sm leading-[1.125rem] font-extralight"
                >
                  <li v-for="item in results">
                    <HeadlessComboboxOption
                      :value="item"
                      :as="NuxtLink"
                      v-slot="{ active }"
                      :to="getPostLink(item.type, item.slug)"
                    >
                      <span
                        :class="{ underline: active }"
                        v-html="item._formatted?.title || item.title"
                        class="[&_strong]:font-bold"
                      >
                      </span>
                    </HeadlessComboboxOption>
                  </li>
                </ul>

                <div
                  v-else
                  class="app-header-search__results-empty text-sm leading-[1.125rem] font-bold"
                >
                  Nie znaleziono szukanego wyniku
                </div>
              </div>
            </div>
          </div>
        </ClientOnly>
      </HeadlessComboboxOptions>
    </HeadlessCombobox>
  </form>
</template>
