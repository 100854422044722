<template>
  <NuxtLink to="/" class="app-header-logo" title="nowoczesna stodoła">
    <img
      src="~/assets/img/logo.svg?url"
      alt="Logo"
      class="h-4 w-auto hidden lg:block"
      width="255"
      height="16"
    />
    <img
      src="~/assets/img/logo-mobile.svg?url"
      alt="Logo"
      class="h-7 w-auto block lg:hidden"
      width="213"
      height="28"
    />
  </NuxtLink>
</template>
