<script setup lang="ts">
const { footerMemu } = useFooter();

const config = useRuntimeConfig();
</script>

<template>
  <nav
    class="app-footer-menu font-light text-[0.875rem] leading-[0.875rem] lg:text-lg lg:leading-[1.75rem]"
  >
    <ul
      class="app-footer-menu__wrapper flex flex-col lg:flex-row items-start lg:items-center gap-[2.333125rem] lg:gap-6"
    >
      <li class="app-footer-menu__item" v-for="item in footerMemu">
        <AppFooterMenuItem v-bind="{ item }" />
      </li>
    </ul>
  </nav>
</template>
