<script setup lang="ts">
import { AppHeaderActionsItem, NuxtLink } from "#components";
import { navigateTo, AuthView } from "#imports";

const { isSearchOpen } = useSearch();
const { openAuthDialog, isLoggedIn, currentUser, logoutUser } = useAuth();
const { isMobile } = useUserDevice();

const { isMobileMenuOpen } = useMenu();

const localePath = useLocalePath();
</script>

<template>
  <nav class="app-header-actions" v-bind="$attrs">
    <ul class="app-header-actions__wrapper flex items-center -m-3">
      <li
        class="app-header-actions__item app-header-actions__item--search z-10"
        :class="{ 'mx-5 mt-7 lg:m-0': isSearchOpen }"
      >
        <AppHeaderActionsItem
          tag="button"
          @click="isSearchOpen = !isSearchOpen"
          title="Szukaj"
        >
          <Icon v-if="isSearchOpen" name="ns:x-circle" />
          <Icon v-else name="ns:search" />
        </AppHeaderActionsItem>
      </li>

      <template v-if="!isMobile || !isSearchOpen">
        <li
          v-if="isLoggedIn"
          class="app-header-actions__item app-header-actions__item--favorites"
        >
          <AppHeaderActionsItem
            :tag="NuxtLink"
            title="Biblioteki"
            :to="localePath({ name: 'boards' })"
          >
            <Icon name="ns:heart" />
          </AppHeaderActionsItem>
        </li>

        <li
          v-if="isLoggedIn"
          class="app-header-actions__item app-header-actions__item--user"
        >
          <HeadlessMenu as="div" class="relative">
            <HeadlessMenuButton
              :as="AppHeaderActionsItem"
              tag="button"
              title="Profil użytkownika"
              v-slot="{ open }"
            >
              <AppAvatar :user="currentUser" size="sm" />
              <span class="sr-only lg:not-sr-only">
                {{ currentUser?.first_name }}
              </span>
              <Icon
                name="ns:chevron-down"
                class="transition-transform sr-only lg:not-sr-only !w-6 !h-6"
                :class="{ '-rotate-180': open }"
              />
            </HeadlessMenuButton>

            <HeadlessMenuItems
              as="div"
              :class="[
                'absolute top-full right-3 z-50',
                'py-2',
                'whitespace-nowrap',
                'flex flex-col items-stretch',
                'bg-white border-[0.0625rem] border-grey-200',
                'rounded-[0.25rem]',
              ]"
            >
              <HeadlessMenuItem as="template" v-slot="{ active, close }">
                <button
                  @click="navigateTo(localePath({ name: 'boards' }))"
                  class="w-full flex items-center gap-4 py-2 px-4"
                  :class="{ 'bg-grey-100': active }"
                >
                  <Icon name="ns:heart" class="w-6 h-6" />
                  <span>Moje tablice</span>
                </button>
              </HeadlessMenuItem>

              <HeadlessMenuItem as="template" v-slot="{ active, close }">
                <button
                  @click="navigateTo(localePath({ name: 'account-settings' }))"
                  class="w-full flex items-center gap-4 py-2 px-4"
                  :class="{ 'bg-grey-100': active }"
                >
                  <Icon name="ns:user-cog-2" class="w-6 h-6" />
                  <span>Ustawienia</span>
                </button>
              </HeadlessMenuItem>

              <div
                class="mx-4 my-2 border-0 border-t-[0.0625rem] border-grey-200"
                aria-hidden="true"
              ></div>

              <HeadlessMenuItem as="template" v-slot="{ active }">
                <button
                  @click="logoutUser"
                  class="w-full flex items-center gap-4 py-2 px-4"
                  :class="{ 'bg-grey-100': active }"
                >
                  <Icon name="ns:log-out" class="w-6 h-6" />
                  <span>Wyloguj się</span>
                </button>
              </HeadlessMenuItem>
            </HeadlessMenuItems>
          </HeadlessMenu>
        </li>

        <li
          v-else
          class="app-header-actions__item app-header-actions__item--login"
        >
          <AppHeaderActionsItem
            tag="button"
            title="Zaloguj"
            @click="openAuthDialog(AuthView.Login)"
          >
            <Icon name="ns:user-circle-2" />
          </AppHeaderActionsItem>
        </li>

        <li
          class="app-header-actions__item app-header-actions__item--menu lg:hidden"
        >
          <AppHeaderActionsItem
            tag="button"
            title="Menu"
            @click="isMobileMenuOpen = true"
          >
            <Icon name="ns:menu" />
          </AppHeaderActionsItem>
        </li>
      </template>
    </ul>
  </nav>
</template>
